import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";

import { track } from "shared/boot/analytics";

let isLoggedIn = false;
export default {
  start(router) {
    App.addListener("appUrlOpen", (event) => {
      if (event.url.match("/oauth")) {
        const url = new URL(event.url);
        const path = url.pathname;
        const queryParams = url.search;
        const fullPath = path + queryParams;

        router.push(fullPath);
        Browser.close();
      }

      if (!isLoggedIn) return;

      const redirectUrl = this.getRedirectUrl(event);

      if (redirectUrl !== router.currentRoute) {
        track("Universal Link Opened", {
          type: "Direct Link",
          url: event.url,
          targetUrl: redirectUrl,
        });

        router.push(redirectUrl);
      }
    });
  },
  setIsLoggedIn(value) {
    isLoggedIn = value;
  },
  getRedirectUrl(event) {
    // extract the mediaId from URL
    const matches = event.url.match(/(RA|TS|TC|TL|ON|PR|MA|TW)[0-9]*/g);

    if (!matches) return "/";

    const mentionDetails = this.getMentionDetails(matches[0]);

    switch (mentionDetails.type) {
      case "article":
      case "radio_clip":
      case "tv_super":
      case "tv_logo_appearance":
      case "tv_caption":
      case "tweet":
        return `/${mentionDetails.type}s/${mentionDetails.id}${mentionDetails.params}`;
      case "magazine_article":
      case "paper_article":
        return `/mention-alert/${mentionDetails.type}_mention/${mentionDetails.id}`;
      default:
        return "/";
    }
  },
  getMentionDetails(mentionId) {
    const mediaId = parseInt(
      mentionId.replace(/(RA|TS|TC|TL|ON|PR|MA|TW)/, ""),
      10
    );

    const mediaType = this.getMentionType(mentionId.substring(0, 2));
    const params = mentionId.substring(12);

    return { id: mediaId, type: mediaType, params };
  },
  getMentionType(code) {
    switch (code) {
      case "ON":
        return "article";
      case "MA":
        return "magazine_article";
      case "PR":
        return "paper_article";
      case "TC":
        return "tv_caption";
      case "TS":
        return "tv_super";
      case "TL":
        return "tv_logo_appearance";
      case "RA":
        return "radio_clip";
      case "TW":
        return "tweet";
      default:
        return "";
    }
  },
};
